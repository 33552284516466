import Image from 'next/image';
import styles from './footer.module.scss';
import LoginLink from 'src/components/LoginLink/LoginLink';
import { LegitScript } from '@get-mosh/msh-shared';
import Routes from 'src/constants/Routes';

const items = [
    {
        heading: 'What we treat',
        list: [
            {
                label: 'Hair loss',
                url: Routes.HAIR_LOSS_LANDING_PAGE,
            },
            {
                label: 'Erectile dysfunction',
                url: Routes.ED_LANDING_PAGE,
            },
            {
                label: 'Premature ejaculation',
                url: Routes.PE_LANDING_PAGE,
            },
            {
                label: 'Anti-ageing',
                url: Routes.SKIN_ANTI_AGEING,
            },
            {
                label: 'Acne',
                url: Routes.SKIN_LANDING_PAGE,
            },
            {
                label: 'Mental health',
                url: Routes.MENTAL_HEALTH_LANDING_PAGE,
            },
            {
                label: 'Enlarged prostate',
                url: Routes.PROSTATE_LANDING_PAGE,
            },
            {
                label: 'Diabetes',
                url: Routes.DIABETES_INITIAL_QUIZ,
            },
            {
                label: 'Weight loss',
                url: Routes.WEIGHT_LOSS_LANDING_PAGE,
            },
        ],
    },
    {
        heading: 'About',
        list: [
            {
                label: 'How it works',
                url: Routes.HOW_IT_WORKS,
            },
            {
                label: 'Our story',
                url: Routes.ABOUT_US,
            },
            {
                label: 'Our practitioners',
                url: Routes.OUR_DOCTORS,
            },
            {
                label: 'Join our community',
                url: Routes.FACEBOOK_GROUP,
            },
            {
                label: 'Press',
                url: Routes.PRESS,
            },
            {
                label: 'Careers',
                url: Routes.CAREERS,
            },
            {
                label: 'Blog',
                url: Routes.BLOG,
            },
            {
                label: 'Sitemap',
                url: Routes.SITEMAP,
            },
        ],
    },
    {
        heading: 'Account',
        list: [
            {
                label: 'Login',
                url: Routes.LOGIN,
            },
            {
                paymentMethods: [
                    {
                        label: 'Visa',
                        image: {
                            src: '/images/icons/VISA.jpg',
                            width: 40,
                            height: 24,
                        },
                    },
                    {
                        label: 'Mastercard',
                        image: {
                            src: '/images/icons/mastercard.jpg',
                            width: 40,
                            height: 24,
                        },
                    },
                    {
                        label: 'amex',
                        image: {
                            src: '/images/icons/Amex.jpg',
                            width: 40,
                            height: 24,
                        },
                    },
                    {
                        label: 'PayPal',
                        image: {
                            src: '/images/icons/Paypal.jpg',
                            width: 40,
                            height: 24,
                        },
                    },
                    {
                        label: 'Afterpay',
                        image: {
                            src: '/images/icons/afterpay.jpg',
                            width: 40,
                            height: 24,
                        },
                    },
                    {
                        label: 'Apple Pay',
                        image: {
                            src: '/images/icons/apple.jpg',
                            width: 40,
                            height: 24,
                        },
                    },
                    {
                        label: 'Google Pay',
                        image: {
                            src: '/images/icons/google.jpg',
                            width: 40,
                            height: 24,
                        },
                    },
                ],
            },
            {
                label: 'Australia Post ID verification',
                image: {
                    src: '/images/icons/digital-id-logo.svg',
                    width: 50,
                    height: 40,
                },
                url: null,
            },
        ],
    },
    {
        heading: 'Support',
        list: [
            {
                label: 'Pricing',
                url: Routes.PRICING,
            },
            {
                label: 'FAQ',
                url: Routes.SUPPORT_FAQ,
                target: '_blank',
            },
            {
                label: 'Contact',
                url: Routes.CONTACT_US,
            },
        ],
    },
    {
        heading: 'Legal',
        list: [
            {
                label: 'Terms of use',
                url: Routes.TERMS_OF_USE,
            },
            {
                label: 'Privacy policy',
                url: Routes.PRIVACY_POLICY,
            },
        ],
    },
];

export default function FooterNavigation() {
    return (
        <>
            {items.map((block, index) => (
                <div
                    className={styles['navigation'] + ' mb-6 w-full md:w-1/3'}
                    key={index}
                >
                    <p className="mb-4">{block.heading}</p>
                    <ul className={styles['navigation-list']}>
                        {block.list.map((e, i) => (
                            <li key={i}>
                                {e.url ? (
                                    e.label === 'Login' ? (
                                        <LoginLink />
                                    ) : (
                                        <a
                                            href={e.url}
                                            title={e.label}
                                            className="block pb-2 md:pb-1"
                                            target={e.target}
                                        >
                                            {e.label}
                                        </a>
                                    )
                                ) : e.paymentMethods ? (
                                    <div className="flex gap-1 py-4 md:grid md:w-[176px] md:grid-cols-4 md:gap-y-1">
                                        {e.paymentMethods.map((method, j) => (
                                            <Image
                                                key={j}
                                                src={method.image.src}
                                                alt={method.label}
                                                width={method.image.width}
                                                height={method.image.height}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        {e.image ? (
                                            <Image
                                                src={e.image.src}
                                                height={e.image.height}
                                                width={e.image.width}
                                                className={
                                                    styles['footer-logos']
                                                }
                                                alt={e.label}
                                            />
                                        ) : (
                                            e.label
                                        )}
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            <LegitScript id="3894492" />
        </>
    );
}
