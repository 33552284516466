import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import Image from 'next/image';
import useMoshyUrl from 'src/hooks/useMoshyUrl';

const list = [
    {
        label: 'Moshy',
        image: '/images/home/footer/moshy-icon.png',
        titleImage: {
            src: '/images/home/footer/moshy-title.png',
            width: 64,
            height: 30,
            alt: 'label',
        },
        text: 'Visit getmoshy.com.au',
        url: undefined,
    },
    {
        label: 'THM',
        image: '/images/home/footer/thm-icon.png',
        titleImage: {
            src: '/images/home/footer/thm-title.png',
            width: 113,
            height: 34,
            alt: 'label',
        },
        text: 'Visit www.healthymummy.com',
        url: 'https://www.healthymummy.com?utm_source=Mosh&utm_medium=Mosh-footer&utm_campaign=our-brands',
    },
] as const;

export default function MoshLogoLink() {
    const moshyUrl = useMoshyUrl(
        'Mosh',
        'website-footer',
        'Mosh_Website_Footer-Moshy',
        'moshy-footer-banner',
        'sister-brand-moshy',
    );

    return (
        <section className="my-6">
            <div className="font-body font-semibold tracking-[-.01em] text-grey-300">
                Our brands
            </div>
            <div className="xl:flex xl:gap-2">
                {list.map((item, index) => (
                    <a
                        className="flex xl:w-fit"
                        key={index}
                        title={item.label}
                        href={item.url || moshyUrl}
                        target="_blank"
                    >
                        <div className="mt-2 flex rounded-2xl bg-green-faint-dark shadow-[0_5px_20px_-10px_rgba(19,40,18,0.1)] max-sm:w-[100%] xl:w-fit">
                            <Image
                                className="m-4 object-contain"
                                src={item.image}
                                width={52}
                                height={52}
                                alt={item.label}
                            />
                            <div className="my-4 max-sm:w-[60%]">
                                <Image
                                    className="h-[30px] object-contain"
                                    src={item.titleImage.src}
                                    width={item.titleImage.width}
                                    height={item.titleImage.height}
                                    alt={item.label}
                                />
                                <div className="mt-1 font-body text-xs font-normal leading-[120%] text-grey-200">
                                    {item.text}
                                </div>
                            </div>
                            <div className="flex items-center p-4">
                                <FontAwesomeIcon
                                    icon={faArrowUpRightFromSquare}
                                />
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </section>
    );
}
